<template>
    <div>
        <v-dialog
            v-model="itemDialog"
            :retain-focus="false"
            max-width="680"
            persistent
        >
            <v-card>
                <v-card-title class="pa-4 pb-0">{{ title }}</v-card-title>
                <v-card-text class="pa-4 pt-0">
                    <v-form v-model="validItem" ref="addItemForm">
                        <v-row>
                            <v-col class="mt-0" cols="6">
                                <v-text-field
                                    v-model="elementItem.name"
                                    hide-details="auto"
                                    label="Name"
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [rules.required]
                                            : []
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="2"
                                v-if="element.fields.includes('unit')"
                            >
                                <v-autocomplete
                                    v-model="elementItem.unit"
                                    :items="dimensionUnits"
                                    required
                                    label="Dimensions Units"
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [rules.required]
                                            : []
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="2"
                                v-if="element.fields.includes('THK')"
                            >
                                <v-autocomplete
                                    v-model="elementItem.thkUnit"
                                    :items="thkUnit"
                                    required
                                    label="THK Units"
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [rules.required]
                                            : []
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="2"
                                v-if="
                                    element.fields.includes('max_span') ||
                                        element.fields.includes('embedment')
                                "
                            >
                                <v-autocomplete
                                    v-model="elementItem.detailUnit"
                                    :items="detailUnit"
                                    required
                                    :label="
                                        `${
                                            element.fields.includes(
                                                'max_span'
                                            ) &&
                                            !element.fields.includes(
                                                'embedment'
                                            )
                                                ? 'Max Span'
                                                : !element.fields.includes(
                                                      'max_span'
                                                  ) &&
                                                  element.fields.includes(
                                                      'embedment'
                                                  )
                                                ? 'Embedment'
                                                : 'Details'
                                        } Units`
                                    "
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [rules.required]
                                            : []
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="3"
                                v-if="element.fields.includes('dimensionsX')"
                            >
                                <v-text-field
                                    v-model="elementItem.dimensionsX"
                                    hide-details="auto"
                                    label="Width (X)"
                                    prepend-icon="mdi-axis-y-arrow"
                                    :suffix="
                                        elementItem.unit ? elementItem.unit : ''
                                    "
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [
                                                  rules.required,
                                                  rules.numberAndCero,
                                              ]
                                            : [rules.numberAndCero]
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="3"
                                v-if="element.fields.includes('dimensionsY')"
                            >
                                <v-text-field
                                    v-model="elementItem.dimensionsY"
                                    hide-details="auto"
                                    label="Height (Y)"
                                    prepend-icon="mdi-axis-z-arrow"
                                    :suffix="
                                        elementItem.unit ? elementItem.unit : ''
                                    "
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [
                                                  rules.required,
                                                  rules.numberAndCero,
                                              ]
                                            : [rules.numberAndCero]
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="3"
                                v-if="element.fields.includes('max_span')"
                            >
                                <v-text-field
                                    style="text-align: center;"
                                    v-model="elementItem.max_span_left"
                                    hide-details="auto"
                                    :label="
                                        `Max span (${
                                            elementItem.detailUnit == 'Imperial'
                                                ? 'ft'
                                                : elementItem.detailUnit
                                                ? 'cm'
                                                : ''
                                        })`
                                    "
                                    :rules="[rules.numberAndCero]"
                                    :suffix="
                                        `${
                                            elementItem.detailUnit == 'Imperial'
                                                ? 'ft'
                                                : elementItem.detailUnit
                                                ? 'cm'
                                                : ''
                                        }`
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="3"
                                v-if="element.fields.includes('max_span')"
                            >
                                <v-text-field
                                    style="text-align: center;"
                                    v-model="elementItem.max_span_rigth"
                                    hide-details="auto"
                                    :label="
                                        `Max span (${
                                            elementItem.detailUnit == 'Imperial'
                                                ? 'in'
                                                : elementItem.detailUnit
                                                ? 'mm'
                                                : ''
                                        })`
                                    "
                                    :rules="[rules.numberAndCero]"
                                    :suffix="
                                        `${
                                            elementItem.detailUnit == 'Imperial'
                                                ? 'in'
                                                : elementItem.detailUnit
                                                ? 'mm'
                                                : ''
                                        }`
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="3"
                                v-if="element.fields.includes('dimensionsL')"
                            >
                                <v-text-field
                                    v-model="elementItem.dimensionsL"
                                    hide-details="auto"
                                    label="Length (Z)"
                                    prepend-icon="mdi-axis-x-arrow"
                                    :suffix="
                                        elementItem.unit ? elementItem.unit : ''
                                    "
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [rules.required, rules.number]
                                            : []
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="6"
                                v-if="element.fields.includes('deflection')"
                            >
                                <v-text-field
                                    v-model="elementItem.deflection"
                                    hide-details="auto"
                                    prefix="L/"
                                    label="Deflection Criteria"
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [rules.required, rules.number]
                                            : []
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                v-if="element.fields.includes('THK')"
                            >
                                <v-text-field
                                    v-model="elementItem.THK"
                                    hide-details="auto"
                                    :label="
                                        `THK (${
                                            elementItem.thkUnit
                                                ? elementItem.thkUnit
                                                : ''
                                        })`
                                    "
                                    :suffix="
                                        elementItem.thkUnit
                                            ? elementItem.thkUnit
                                            : ''
                                    "
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [rules.required]
                                            : []
                                    "
                                />
                            </v-col>

                            <v-col
                                class="mt-0"
                                cols="6"
                                v-if="element.fields.includes('qty')"
                            >
                                <v-text-field
                                    v-model="elementItem.qty"
                                    hide-details="auto"
                                    label="QTY"
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [rules.required, rules.number]
                                            : [rules.optionalNumber]
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="6"
                                v-if="element.fields.includes('embedment')"
                            >
                                <v-text-field
                                    v-model="elementItem.embedment"
                                    hide-details="auto"
                                    :label="
                                        `Embedment ${
                                            elementItem.detailUnit == 'Imperial'
                                                ? 'in'
                                                : 'mm'
                                        }`
                                    "
                                    :rules="[rules.numberAndCero]"
                                    :suffix="
                                        `${
                                            elementItem.detailUnit == 'Imperial'
                                                ? 'in'
                                                : elementItem.detailUnit
                                                ? 'mm'
                                                : ''
                                        }`
                                    "
                                />
                            </v-col>
                            <v-col
                                class="mt-0"
                                cols="6"
                                v-if="element.fields.includes('specification')"
                            >
                                <v-text-field
                                    v-model="elementItem.specification"
                                    hide-details="auto"
                                    label="Specification"
                                    :rules="
                                        element.type != 'OTHERS'
                                            ? [rules.required]
                                            : []
                                    "
                                />
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12">
                                <v-text-field
                                    v-model="elementItem.comments"
                                    hide-details="auto"
                                    label="Comments"
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions class="pa-4">
                    <v-btn color="secondary" text @click="closeItemDialog">
                        Cancel
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        :disabled="!validItem"
                        text
                        @click="saveElementItem"
                    >
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-expansion-panels mandatory flat class="mb-4">
            <v-expansion-panel class="mb-4">
                <v-expansion-panel-header
                    color="grey lighten-3"
                    expand-icon="mdi-plus"
                    class="pa-2"
                    style="min-height: unset;"
                >
                    {{ element.type.toUpperCase() }}
                    <template v-slot:actions>
                        <v-btn icon @click="openItemDialog()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-card class="elevation-0">
                        <v-data-table
                            :headers="headers"
                            color="grey lighten-3"
                            class="elevation-0"
                            :items="items"
                            :items-per-page="-1"
                            :mobile-breakpoint="0"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:[`item.name`]="{ item }">
                                <span>
                                    {{ item.name ? item.name : '--' }}
                                </span>
                            </template>
                            <template v-slot:[`item.dimensions`]="{ item }">
                                <span>
                                    {{
                                        item.dimensions ? item.dimensions : '--'
                                    }}</span
                                >
                            </template>
                            <template v-slot:[`item.specification`]="{ item }">
                                <span>
                                    {{
                                        item.specification
                                            ? item.specification
                                            : '--'
                                    }}</span
                                >
                            </template>
                            <template v-slot:[`item.embedment`]="{ item }">
                                <span>
                                    {{
                                        item.embedment
                                            ? `${
                                                  item.embedment
                                                      ? `${item.embedment}${
                                                            item.detailUnit ==
                                                            'Metric'
                                                                ? 'mm'
                                                                : '"'
                                                        }`
                                                      : ''
                                              } `
                                            : '--'
                                    }}</span
                                >
                            </template>
                            <template v-slot:[`item.THK`]="{ item }">
                                <span>
                                    {{
                                        item.THK && item.thkUnit
                                            ? `${item.THK}${
                                                  item.thkUnit == 'in'
                                                      ? `"`
                                                      : item.thkUnit
                                              }`
                                            : '--'
                                    }}</span
                                >
                            </template>
                            <template v-slot:[`item.max_span`]="{ item }">
                                <span>
                                    {{
                                        item.max_span_left ||
                                        item.max_span_rigth
                                            ? `${
                                                  item.max_span_left
                                                      ? `${item.max_span_left}${
                                                            item.detailUnit ==
                                                            'Metric'
                                                                ? 'cm'
                                                                : "'"
                                                        }`
                                                      : ''
                                              } ${
                                                  item.max_span_rigth
                                                      ? `${
                                                            item.max_span_rigth
                                                        }${
                                                            item.detailUnit ==
                                                            'Metric'
                                                                ? 'mm'
                                                                : '"'
                                                        }`
                                                      : ''
                                              }`
                                            : '--'
                                    }}</span
                                >
                            </template>
                            <template v-slot:[`item.qty`]="{ item }">
                                <span> {{ item.qty ? item.qty : '--' }}</span>
                            </template>
                            <template v-slot:[`item.deflection`]="{ item }">
                                <span>
                                    {{
                                        item.deflection
                                            ? `L/${item.deflection}`
                                            : '--'
                                    }}</span
                                >
                            </template>
                            <template v-slot:[`item.actions`]="{ item }">
                                <v-btn icon small @click="openItemDialog(item)">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-btn>
                                <v-btn icon small @click="deleteItem(item)">
                                    <v-icon small>mdi-trash-can</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>

                        <v-dialog
                            v-model="deleteDialog"
                            persistent
                            max-width="400px"
                        >
                            <v-card>
                                <v-card-title class="text-h5"
                                    >Delete Element</v-card-title
                                >
                                <v-card-text>
                                    Are you sure you want to delete this
                                    element?
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        text
                                        color="secondary"
                                        @click="closeDeleteDialog"
                                    >
                                        Close
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="deleteElement"
                                        text
                                        color="error"
                                    >
                                        Delete
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import _ from 'lodash'
// import { mapState } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
export default {
    name: 'NewStructuralElementForm',
    props: {
        element: {
            type: Object,
            required: false,
            default: () => {},
        },
        itemElements: {
            type: Array,
            required: false,
            default: () => [],
        },
        elements: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            title: '',
            validItem: false,
            itemDialog: false,
            activateWeight: false,
            activateEmbedment: false,
            activateTHK: false,
            // activateTHK: false,
            rules: {
                required: v => !!v || 'Required',
                number: v => ((v || '0') && v > 0) || 'Invalid number',
                numberAndCero: v =>
                    ((v || '0' || 0) && v >= 0) || 'Invalid number',
                optionalNumber: v => !v || v == '' || v > 0 || 'Invalid number',
            },
            // element: {},
            deleteDialog: false,
            headers: [
                {
                    text: 'Name',
                    value: 'name',
                    class: 'grey lighten-3',
                    width: '250px',
                },
                {
                    text: 'Dimensions',
                    value: 'dimensions',
                    class: 'grey lighten-3',
                    align: 'center',
                },
                {
                    text: 'Specification',
                    value: 'specification',
                    class: 'grey lighten-3',
                    align: 'center',
                },
                {
                    text: 'Embedment',
                    value: 'embedment',
                    class: 'grey lighten-3',
                    align: 'center',
                },
                {
                    text: 'THK',
                    value: 'THK',
                    class: 'grey lighten-3',
                    align: 'center',
                },
                {
                    text: 'Max span',
                    value: 'max_span',
                    class: 'grey lighten-3',
                    align: 'center',
                },
                {
                    text: `QTY`,
                    value: 'qty',
                    class: 'grey lighten-3',
                    align: 'center',
                },
                {
                    text: `Deflection`,
                    value: 'deflection',
                    class: 'grey lighten-3',
                    align: 'center',
                },
                {
                    text: `Comments`,
                    value: 'comments',
                    class: 'grey lighten-3',
                    align: 'center',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    align: 'center',
                    sortable: false,
                    class: 'grey lighten-3',
                },
            ],
            elementItem: {},
            elementItems: [],
            editingElementItem: -1,
            itemToDelete: {},
            dimensionUnits: ['in', 'cm'],
            thkUnit: ['in', 'mm'],
            // embedmentUnit: ['in', 'mm'],
            detailUnit: ['Imperial', 'Metric'],
        }
    },
    computed: {
        items() {
            return this.setElements()
        },
    },

    methods: {
        setElements() {
            this.elementItems = []
            if (this.itemElements && this.itemElements.length > 0) {
                const index = this.itemElements.findIndex(
                    element => element.type == this.element.type
                )
                if (index > -1) {
                    this.itemElements.forEach(item => {
                        if (item.type == this.element.type) {
                            this.elementItems.push(item.items)
                        }
                    })
                }
            }
            this.editingElementItem = -1
            return this.elementItems
        },
        openItemDialog(item) {
            if (item) {
                this.elementItem = _.cloneDeep(item)
                this.editingElementItem = this.elementItems.findIndex(
                    i => i.id === item.id
                )
            }
            this.title = this.element.type ? this.element.type : ''
            this.itemDialog = true
            item = undefined
        },
        closeItemDialog() {
            this.elementItem = {}
            this.editingElementItem = -1
            this.$refs.addItemForm.reset()
            this.itemDialog = false
        },
        saveElementItem() {
            this.elementItem.dimensions = `${
                this.elementItem.dimensionsX
                    ? `${this.elementItem.dimensionsX}${
                          this.elementItem.unit == 'in' ? `"` : 'cm'
                      }`
                    : ''
            }${
                this.elementItem.dimensionsY
                    ? `x${this.elementItem.dimensionsY}${
                          this.elementItem.unit == 'in' ? `"` : 'cm'
                      }`
                    : ''
            }${
                this.elementItem.dimensionsL
                    ? `x${this.elementItem.dimensionsL}${
                          this.elementItem.unit == 'in' ? `"` : 'cm'
                      }`
                    : ''
            }${
                this.element.type == 'MULLIONS' &&
                this.elementItem.THK &&
                this.elementItem.thkUnit
                    ? `x${this.elementItem.THK}${
                          this.elementItem.thkUnit == 'in' ? `"` : 'mm'
                      }`
                    : ''
            }`
            Object.keys(this.elementItem).forEach(key => {
                if (
                    [
                        'dimensionsX',
                        'dimensionsY',
                        'dimensionsL',
                        'deflection',
                        'max_span_left',
                        'max_span_rigth',
                        'embedment',
                        'qty',
                    ].includes(key) &&
                    (this.elementItem[key] ||
                        [0, '0'].includes(this.elementItem[key]))
                ) {
                    this.elementItem[key] = Number(this.elementItem[key])
                }
            })
            const item = _.cloneDeep(this.elementItem)

            //if item exists
            if (this.editingElementItem > -1) {
                this.elementItems = this.elementItems.splice(
                    this.editingElementItem,
                    1,
                    item
                )
            } else {
                //if item does not exist
                item.id = item.id ? item.id : this.generateUniqueId()
                this.elementItems.push(item)
            }
            this.$emit('saveItems', this.element.type, item ? item : [])

            this.$refs.addItemForm.reset()
            this.itemDialog = false
            this.elementItem = {}
            this.editingElementItem = -1
        },
        deleteItem(item) {
            this.itemToDelete = item
            this.deleteDialog = true
        },
        closeDeleteDialog() {
            this.itemToDelete = {}
            this.deleteDialog = false
        },
        deleteElement() {
            const index = this.elementItems.findIndex(
                item => item.id == this.itemToDelete.id
            )
            if (index >= 0) {
                this.elementItems.splice(index, 1)
                this.$emit('deleteItems', this.itemToDelete)
            }
            this.elementItems.splice(index, 1)
            this.$emit(
                'deleteItems',
                this.elementItems?.length > 0 ? this.elementItems : []
            )
            this.deleteDialog = false
        },
        generateUniqueId() {
            const newId = uuidv4()
            return this.elements.some(element => element.id === newId)
                ? this.generateUniqueId()
                : newId
        },
    },
}
</script>

<style scoped>
.no-border {
    border: none !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
tr:hover {
    background: unset !important;
}
th,
td {
    font-size: 0.875rem !important;
}
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
    padding: 0;
}
.v-expansion-panels {
    z-index: 0;
}
</style>
